import * as React from "react";

import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import { kebabCase } from "lodash";

import Content, { HTMLContent } from "../components/Content";
import Seo from "../components/Seo";
import FullWidthImage from "../components/FullWidthImage";
import DisplayContentHeader from "../components/DisplayContentHeader";
import Pagination from "../components/Pagination";
import { v4 } from "uuid";

const pluralize = require("pluralize");

class TagRoute extends React.Component {
  render() {
    let pageContext = this.props.pageContext;

    const post = this.props.data.markdownRemark;
    let { title, footer, images, summary, breadcrumbs, options } =
      post.frontmatter;

    let { parent, parentSlug } = breadcrumbs;
    const body = post.html;
    const slug = post.fields.slug;
    const PageContent = HTMLContent || Content;

    const showTitle = options.showTitle;

    let heroImage, footerImage;
    if (images && images.hero) heroImage = getImage(images.hero) || images.hero;
    if (images && images.footer)
      footerImage = getImage(images.footer) || images.footer;

    const posts = this.props.data.allMarkdownRemark.edges;
    let renderBreadcrumbs, photoBreadcrumbs;

    const postLinks = posts.map((post) => {
      if (
        post.node.frontmatter.photos &&
        post.node.frontmatter.photos.showGallery &&
        post.node.frontmatter.photos.photo
      ) {
        renderBreadcrumbs = [];
        photoBreadcrumbs = [];

        if (!post.node.frontmatter.titles) post.node.frontmatter.titles = {};

        if (post.node.fields.slug.indexOf("blog") === -1) {
          renderBreadcrumbs.push(
            <Link to="/videos" title="Videos">
              <h3 className="is-size-5 mb-0">Videos</h3>
            </Link>
          );
        } else {
          renderBreadcrumbs.push(
            <Link to="/blog" title="Blog">
              <h3 className="is-size-5 mb-0">Blog</h3>
            </Link>
          );
        }

        renderBreadcrumbs.push(
          <Link
            to={`/category/${kebabCase(post.node.frontmatter.category)}`}
            title={`${kebabCase(post.node.frontmatter.category)}`}
          >
            <h3 className="is-size-5 mb-0">{post.node.frontmatter.category}</h3>
          </Link>
        );
        renderBreadcrumbs.push(
          <Link to={post.node.fields.slug} title={post.node.frontmatter.title}>
            <h2 className="is-size-5 mb-0">{post.node.frontmatter.title}</h2>
          </Link>
        );

        photoBreadcrumbs.push(
          <Link
            to={`${post.node.fields.slug}gallery/`}
            title={`${post.node.frontmatter.title} Photo Gallery`}
          >
            <h4 className="is-size-6 mb-0 mt-0 pt-0 ">Photo Gallery</h4>
          </Link>
        );

        photoBreadcrumbs.push(
          <Link
            to={`${post.node.fields.slug}gallery/`}
            title={`${post.node.frontmatter.title} Photo Gallery`}
          >
            <h4 className="is-size-6 mb-0 mt-0 pt-0 ">
              {post.node.frontmatter.title}
            </h4>
          </Link>
        );
        if (renderBreadcrumbs && renderBreadcrumbs.length) {
          return (
            <React.Fragment key={v4()}>
              <nav
                className="breadcrumb has-succeeds-separator pb-0 mb-0"
                aria-label="breadcrumbs"
              >
                <ul className="ml-0 pl-0">
                  {renderBreadcrumbs.map((breadcrumb) => {
                    return (
                      <React.Fragment key={v4()}>
                        <li>{breadcrumb}</li>
                      </React.Fragment>
                    );
                  })}

                  {photoBreadcrumbs && photoBreadcrumbs.length ? (
                    <ul className="ml-0 mt-2 mb-2 pl-4">
                      {photoBreadcrumbs.map((breadcrumb) => {
                        return (
                          <React.Fragment key={v4()}>
                            <li className="has-height-20">{breadcrumb}</li>
                          </React.Fragment>
                        );
                      })}
                    </ul>
                  ) : null}
                </ul>
              </nav>
            </React.Fragment>
          );
        } else {
          return <React.Fragment key={v4()}></React.Fragment>;
        }
      } else {
        renderBreadcrumbs = [];

        if (post.node.fields.slug.indexOf("blog") === -1) {
          renderBreadcrumbs.push(
            <Link to="/videos" title="Videos">
              <h3 className="is-size-5 mb-0">Videos</h3>
            </Link>
          );
        } else {
          renderBreadcrumbs.push(
            <Link to="/blog" title="Blog">
              <h3 className="is-size-5 mb-0">Blog</h3>
            </Link>
          );
        }

        renderBreadcrumbs.push(
          <Link
            to={`/category/${kebabCase(post.node.frontmatter.category)}`}
            title={`${kebabCase(post.node.frontmatter.category)}`}
          >
            <h3 className="is-size-5 mb-0">{post.node.frontmatter.category}</h3>
          </Link>
        );
        renderBreadcrumbs.push(
          <Link to={post.node.fields.slug} title={post.node.frontmatter.title}>
            <h2 className="is-size-5 mb-0">{post.node.frontmatter.title}</h2>
          </Link>
        );

        if (renderBreadcrumbs && renderBreadcrumbs.length) {
          return (
            <React.Fragment key={v4()}>
              <nav
                className="breadcrumb has-succeeds-separator pb-0 mb-0"
                aria-label="breadcrumbs"
              >
                <ul className="ml-0 pl-0">
                  {renderBreadcrumbs.map((breadcrumb) => {
                    return (
                      <React.Fragment key={v4()}>
                        <li>{breadcrumb}</li>
                      </React.Fragment>
                    );
                  })}
                </ul>
              </nav>
            </React.Fragment>
          );
        } else {
          return <React.Fragment key={v4()}></React.Fragment>;
        }
      }
    });
    const tag = this.props.pageContext.tag;

    const totalCount = this.props.data.allMarkdownRemark.totalCount;
    const tagHeader = `${totalCount} ${pluralize(
      "Post",
      totalCount
    )} tagged with "${titleCase(tag)}"`;

    const tagRanks = relatedTags(tag, posts);

    let relatedLinks = null;

    if (tagRanks.length) {
      relatedLinks = tagRanks.map((tag) => (
        <React.Fragment key={v4()}>
          <li>
            <Link title={tag} to={`/tag/${kebabCase(tag)}/`}>
              <span className="tag is-medium">{tag}</span>
            </Link>
          </li>
        </React.Fragment>
      ));
    }

    if (!summary) {
      summary = `Resources tagged with "${tag}".`;
    }

    let imageSrc =
      images.hero.childImageSharp.gatsbyImageData.images.fallback.src;

    let capitalizedTag = tag
      .toLowerCase()
      .replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
      });

    // NOTE: the DisplayContentHeader is correctly configured for this particular view
    //
    // title={capitalizedTag}
    // parent={title}
    // parentSlug={slug}
    // grandparent={parent}
    // grandparentSlug={parentSlug}
    //

    return (
      <Layout>
        <div>
          <Seo
            title={`Tag > ${capitalizedTag}`}
            keywords={`${tag.toLowerCase()}, ${tagRanks
              .join(", ")
              .toLowerCase()}`}
            summary={summary}
            image={imageSrc}
            article={false}
          />

          {heroImage ? (
            <FullWidthImage
              img={heroImage}
              title={`"${titleCase(tag)}"`}
              subheading="Tag"
              showTitle={showTitle}
            />
          ) : null}

          <section className="section section--gradient">
            <div className="container">
              <div className="content">
                <div className="content">
                  <DisplayContentHeader
                    title={capitalizedTag}
                    parent={title}
                    parentSlug={slug}
                    grandparent={parent}
                    grandparentSlug={parentSlug}
                  />
                </div>

                <PageContent className="content" content={body} />

                <h3 className="title is-size-4 is-bold-light">{tagHeader}</h3>
                <ul>{postLinks}</ul>
                <br className="clear" />
                <Pagination pageContext={pageContext} />
                <hr />
                {relatedLinks && relatedLinks.length > 0 ? (
                  <div className="content">
                    <h3 className="title is-size-4 has-text-weight-bold is-bold-light">
                      Related Tags:
                    </h3>
                    <ul className="taglist">{relatedLinks}</ul>
                    <div />
                  </div>
                ) : null}
                <div>
                  <Link to="/tags/">
                    <h3 className="title is-size-4 has-text-weight-bold is-bold-light ">
                      Browse All Tags
                    </h3>
                  </Link>
                </div>
              </div>
            </div>
          </section>

          {footerImage ? (
            <FullWidthImage
              img={footerImage}
              subheading={footer}
              imgPosition="center"
              className="footer-image-text"
              showTitle={true}
            />
          ) : null}
        </div>
      </Layout>
    );
  }
}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(
      frontmatter: { advanced: { templateKey: { eq: "tags-page" } } }
    ) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
        breadcrumbs {
          parent
          parentSlug
          grandparent
          grandparentSlug
        }
        options {
          showTitle
        }
        summary
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___creationDate], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            category
            tags
            photos {
              showGallery
              photo {
                image {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}
/**
 * Find related tags for any given tag
 */

function relatedTags(tag, posts, max = 5) {
  // count tags
  let counts = [];

  posts.forEach((post) => {
    const tags = post.node.frontmatter.tags;
    tags.forEach((tag) => {
      counts[tag] ? counts[tag]++ : (counts[tag] = 1);
    });
  });

  delete counts[tag];

  // build 2D temp array for sorting
  // currently numeric desc

  var tuples = [];

  for (var key in counts) tuples.push([key, counts[key]]);

  tuples.sort(function (b, a) {
    a = a[1];
    b = b[1];

    return a < b ? -1 : a > b ? 1 : 0;
  });

  // return sorted array of tags

  let related = [];
  let k = null;
  //let v = null;

  for (var i = 0; i < tuples.length && related.length < max; i++) {
    k = tuples[i][0];
    //v = tuples[i][1];

    // build related tags

    related.push(k);
  }

  return related;
}
